<template>
  <v-container class="black--text copyright pa-0">
    <v-row>
      <v-col cols="12" md="4">
        <div class="text-center text-md-left">
          <p class="mb-1">{{ $t("footer.name") }}</p>
          <p class="mb-0">Lodín 99, 503 15 Nechanice</p>
          <p class="mb-0">IČ: 71216006, DIČ: CZ71216006</p>
          <p class="mb-0">{{ $t("footer.responsiblePerson") }}: Monika Dvořáková</p>
        </div>

      </v-col>
      <v-col cols="12" md="4">
        <div class="text-center text-md-left">
          <p class="mb-0">Telefon: +420 495 445 152</p>
          <p class="mb-0">E-mail: <a href="mailto:recepce@camplodin.cz">recepce@camplodin.cz</a></p>
          <p class="mb-0">Web: <a href="https://www.camplodin.cz" target="_blank">www.camplodin.cz</a></p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-center text-md-left">
          <a target="_blank" class="black--text" :href="$t('footer.privacyPolicyPdf')">{{
            $t("footer.privacyPolicy") }}</a><span class="mr-1 ml-1">|</span>
          <a target="_blank" class="black--text" :href="$t('footer.aboutUsUrl')">{{ $t("footer.aboutUs") }}</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style lang="scss">
.copyright {
  font-size: 14px;
}

.no-decoration {
  text-decoration: none;
}
</style>
