<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <h3 class="checkout-section-title">{{ $t('checkout.deliveryMethods') }}</h3>
    </v-card-title>
    <v-card-text>
      <v-radio-group v-model="form.shippingMethodId" v-if="shippingMethods.length" :rules="[rules.required]">
        <v-radio v-for="n in shippingMethods" :key="n.id" :label="n.name" :value="n.id"></v-radio>
      </v-radio-group>
      <div v-if="!shippingMethods.length">
        {{ $t('checkout.noDeliveryMethods') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DeliveryMethods",
  props: ["form"],
  data() {
    return {
      rules: window.validationRules.getRules(),
    };
  },
  mounted() {
    if (!this.shippingMethods.length) {
      this.retrieveShippingMethods(this.$i18n.locale);
    }
  },
  watch: {
    "$i18n.locale": function (val) {
      this.retrieveShippingMethods(val);
    },
  },
  computed: {
    ...mapGetters(["shippingMethods", "shippingMethodsLoaded"]),
  },
  methods: {
    ...mapActions(["retrieveShippingMethods"]),
  },
};
</script>
