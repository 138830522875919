<template>
  <div class="cookies" :style="cssProps" v-on:open="open">
    <div class="cookie-container" v-if="cookieBarVisible">
        <div v-if="settings">
          <div v-if="settings" class="cookies2022_settings_screen">
            <div class="cookies2022_text">
              <h5 v-text="text.settings_heading" />
              <p v-text="text.settings_text" />
              <div class="cookies2022_allow_action">
                <button @click="allowAll" class="btn btn-action btn-default" v-text="text.allow_all" />
              </div>
            </div>
            <div class="cookies2022_settings">
              <div class="cookies2022_settings--row">
                <v-switch input-value="true" disabled :label="text.basic"></v-switch>
                <p v-text="text.basic_description" />
              </div>
              <div class="cookies2022_settings--row">
                <v-switch v-model="marketingCookiesEnabled" :label="text.marketing"></v-switch>
                <p v-text="text.marketing_description" />
              </div>
              <div class="cookies2022_settings--row">
                <v-switch v-model="analyticalCookiesEnabled" :label="text.analytical"></v-switch>
                <p v-text="text.analytical_description" />
              </div>
            </div>
          </div>
          <div class="cookies2022_actions">
            <button @click="close" class="btn btn-action btn-default" v-text="text.save" />
          </div>
        </div>

        <div v-else>
          <div class="text-content">
            <h5 v-text="text.heading" />
            <div class="text">
              {{ text.text }} "<a href="#" @click="settings = 1" v-text="text.change_settings"/>".
            </div>
          </div>

          <div class="cookies2022_actions">
            <button @click="settings = true;" class="btn btn-ghost" v-text="text.change_settings" />
            <button @click="allowAll" class="btn btn-action btn-default" v-text="text.accept_all" />
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['lang', 'primaryColor'],
  data() {
    return {
      strings: {
        'cs': {
          'heading': 'Principy ochrany soukromí',
          'text': 'Vážení zákazníci, záleží nám na tom, aby pro Vás byla návštěva naší stránky příjemným zážitkem. Proto pro Vás neustále vylepšujeme naše služby. Stisknutím tlačítka "Přijmout vše", souhlasíte s tím, abychom Vaše údaje zpracovali za účelem zkvalitňování služeb, personalizace reklam a analýzy návštěvnosti. Svůj souhlas můžete samozřejmě kdykoliv upravit kliknutím na ',
          'change_settings': 'Upravit nastavení',
          'settings_heading': 'Nastavení soukromí',
          'settings_text': 'Vážení zákazníci, zde si můžete nastavit povolení pro jednotlivá cookies.',
          'accept_all': 'Povolit vše',
          'allow_all': 'Povolit vše',
          'basic': 'Základní',
          'save': 'Uložit nastavení',
          'basic_description': 'Používání základních cookies nám pomáhá zajistit správné fungování našich webových stránek. Vaše návštěva pak proběhne bez problémů s funkčním obsahem a stane se tak pro Vás příjemným zážitkem.',
          'marketing': 'Marketingové',
          'marketing_description': 'Marketingové cookies využíváme, abychom zaznamenávali údaje o Vašich návštěvách a aktivitách na této webové stránce. Naším cílem je Vám pak nabídnout jen takové reklamní kampaně, které jsou pro Vás relevantní.',
          'analytical': 'Analytické',
          'analytical_description': 'Analytické cookies nám pomáhají porozumět, jak pracujete s naší webovou stránkou. To nám pak umožní web vylepšovat pro Vaše komfortní využívání stránky. Cookies jsou anonymizované a šifrované.',
        },
        'en': {
          'heading': 'Principles of Privacy Protection',
          'text': 'Dear Customers, we do our best so that visiting our website is a pleasant experience for you. That is why we continuously improve our services. By clicking on “Accept all” you agree to our processing of your data in order to improve services, personalize advertising and analyse the number of visits. You can revoke your consent at any time by clicking on ',
          'change_settings': 'Adjust settings',
          'settings_heading': 'Privacy Settings',
          'save': 'Save',
          'settings_text': 'Dear Customers, here you can adjust permissions for individual cookies.',
          'accept_all': 'Allow all',
          'allow_all': 'Allow all',
          'basic': 'Basic',
          'basic_description': 'The use of basic cookies helps us ensure the correct functioning of our website. Your visit will then be trouble-free, with functional contents, and will become a pleasant experience for you.',
          'marketing': 'Marketing',
          'marketing_description': 'We use marketing cookies to record data about your visits and activities on this website. Our goal is to offer you advertising which is relevant for you.',
          'analytical': 'Analytical',
          'analytical_description': 'Analytical cookies help us understand how you work with our website. This will then allow us to improve the site for your comfort. Cookies are anonymised and encrypted.',
        },
        'pl': {
          'heading': 'Zasady prywatności',
          'text': 'Drodzy klienci, zależy nam na tym, aby wizyta na naszej stronie była dla Państwa przyjemnym doświadczeniem. Dlatego stale ulepszamy nasze usługi dla Ciebie. Naciskając przycisk „Akceptuj wszystko”, zgadzasz się, że będziemy przetwarzać Twoje dane w celu ulepszania naszych usług, personalizowania naszych reklam i analizowania naszego ruchu. Oczywiście w każdej chwili możesz edytować swoją zgodę, klikając ',
          'change_settings': 'Edytuj ustawienia',
          'settings_heading': 'Nastavení soukromí',
          'settings_text': 'Szanowni Klienci, tutaj możesz ustawić uprawnienia dla poszczególnych plików cookie.',
          'accept_all': 'Zaakceptuj wszystko',
          'allow_all': 'Zaakceptuj wszystko',
          'basic': 'Podstawowy',
          'save': 'Zapisz ustawienia',
          'basic_description': 'Stosowanie podstawowych plików cookie pomaga nam zapewnić prawidłowe funkcjonowanie naszej strony internetowej. Twoja wizyta odbędzie się wtedy bez żadnych problemów z treścią funkcjonalną i stanie się dla Ciebie przyjemnym doświadczeniem.',
          'marketing': 'Marketing',
          'marketing_description': 'Używamy marketingowych plików cookie do rejestrowania informacji o Twoich wizytach i działaniach na tej stronie. Naszym celem jest oferowanie tylko tych kampanii reklamowych, które są dla Ciebie istotne.',
          'analytical': 'Analityczny',
          'analytical_description': 'Analityczne pliki cookie pomagają nam zrozumieć, w jaki sposób pracujesz z naszą witryną. Umożliwi nam to ulepszenie strony internetowej pod kątem komfortowego korzystania z niej. Pliki cookie są anonimowe i szyfrowane.',
        },
        'de': {
          'heading': 'Datenschutz-Einstellungen',
          'text': 'Sehr geehrte Kunden, wir sind stets bemüht, Ihren Besuch auf unserer Website zu einem angenehmen Erlebnis zu machen. Deshalb verbessern wir ständig unseren Service für Sie. Indem Sie auf die Schaltfläche "Alles akzeptieren" klicken, erklären Sie sich damit einverstanden, dass wir Ihre Daten zum Zwecke der Verbesserung unserer Dienste, der Personalisierung von Anzeigen und der Analyse des Datenverkehrs verarbeiten. Sie können Ihre Zustimmung natürlich jederzeit ändern, indem Sie klicken auf ',
          'change_settings': 'Einstellungen bearbeiten',
          'settings_heading': 'Datenschutz-Einstellungen',
          'settings_text': 'Liebe Kunden, hier können Sie Einstellungen für einzelne Cookies vornehmen.',
          'accept_all': 'Alle akzeptieren',
          'allow_all': 'Alle akzeptieren',
          'save': 'Einstellungen',
          'basic': 'Notwendige Cookies',
          'basic_description': 'Die Verwendung von notwendigen Cookies hilft uns, das ordnungsgemäße Funktionieren unserer Website zu gewährleisten. Ihr Besuch wird dann reibungslos ohne Probleme mit funktionalem Inhalt ablaufen und zu einem angenehmen Erlebnis für Sie werden.',
          'marketing': 'Marketing-Cookies',
          'marketing_description': 'Wir verwenden Marketing-Cookies, um Daten über Ihre Besuche und Aktivitäten auf dieser Website aufzuzeichnen. Unser Ziel ist es dann, Ihnen nur Werbekampagnen anzubieten, die für Sie relevant sind.',
          'analytical': 'Analytische-Cookies',
          'analytical_description': 'Analytische Cookies helfen uns zu verstehen, wie Sie mit unserer Website interagieren. Auf diese Weise können wir die Website in Ihrem Sinne verbessern. Die Cookies sind anonymisiert und verschlüsselt.',
        }
      },
      settings: false,
      cookieBarVisible: false,
      marketingCookiesEnabled: false,
      analyticalCookiesEnabled: false
    }
  },
  methods: {
    open() {
      this.cookieBarVisible = true;
    },
    allowAll() {
      this.marketingCookiesEnabled = true;
      this.analyticalCookiesEnabled = true;
      this.cookieBarVisible = false;
    },
    denyAll() {
      this.marketingCookiesEnabled = false;
      this.analyticalCookiesEnabled = false;
      this.cookieBarVisible = false;
    },
    close() {
      this.cookieBarVisible = false;
    },
  },
  mounted() {

    this.$root.$on('open', function () {
      this.open();
    }.bind(this))

    if (window.localStorage.getItem('MarketingCookiesConsent') === 'true') {
      this.marketingCookiesEnabled = true;
    }

    if (window.localStorage.getItem('AnalyticalCookiesConsent') === 'true') {
      this.analyticalCookiesEnabled = true;
    }

    if (window.localStorage.getItem('CookiesConsent') !== 'true') {
      this.cookieBarVisible = true;
    }
  },
  computed: {
    cssProps() {
      return {
        '--cookie-bar-primary-color': this.primaryColor,
      }
    },
    text() {
      return this.strings[this.lang];
    }
  },
  watch: {
    marketingCookiesEnabled(val) {
      if (val) {
        window.localStorage.setItem('MarketingCookiesConsent', 'true');
      } else {
        window.localStorage.setItem('MarketingCookiesConsent', 'false');
      }
    },
    analyticalCookiesEnabled(val) {
      if (val) {
        window.localStorage.setItem('AnalyticalCookiesConsent', 'true');
      } else {
        window.localStorage.setItem('AnalyticalCookiesConsent', 'false');
      }
    },
    cookieBarVisible(val) {
      if (val) {
        window.localStorage.setItem('CookiesConsent', 'false');
      } else {
        window.localStorage.setItem('CookiesConsent', 'true');
      }
    },
  },
}
</script>
<style scoped>

.cookies {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.cookies .cookie-container {
  box-shadow: 0 0 100px 0 rgb(1 2 2 / 30%);
  border-radius: 15px;
  width: 100%;
  max-width: 546px;
  background: #fff;
  position: fixed;
  padding: 15px;
  right: 20px;
  bottom: 20px;
}

@media (max-width: 640px) {
  .cookies .cookie-container {
    right: 0;
    bottom: 0;
    max-width: 100%;
  }
}

.cookies2022_settings_screen {
  max-height: 500px;
  overflow-x: auto;
  padding: 15px;
}

.cookies a {
  color: var(--cookie-bar-primary-color);
}

.cookies2022_actions {
  position: static;
  clear: both;
}

.cookies2022_actions {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
}

@media (min-width: 1000px) {
  .cookies2022_actions button {
    margin-left: 6px;
  }
}

.cookies h5 {
  font-size: 16px;
  font-weight: 700 !important;
  margin-bottom: 15px;
  line-height: 16px;
  text-decoration: none;
  font-family: Open Sans, sans-serif;
}

.cookies .text {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: -0.2px;
  font-family: Open Sans, sans-serif;
}

@media (min-width: 1000px) {
  .cookies2022_actions {
    justify-content: flex-end;
    flex-direction: row;
  }
}

.btn-action {
  background: var(--cookie-bar-primary-color) !important;
  color: #fff;

}

.cookies .cookies2022_actions button {
  margin-bottom: 6px;
}

.cookies .btn-ghost {
  color: --var(--cookie-bar-primary-color);
  border: 1px solid var(--cookie-bar-primary-color);
}

.cookies .btn-default,
.cookies .btn-ghost {
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 15px;
  font-family: Arial, sans-serif;
  background: #fff;
}
</style>